'use client';
import React, { useEffect, useState } from "react";
import SingleProductCard from "../SharedComponents/SingleProductCard";
import Link from "next/link";
import axios from "axios";

const ProductArea = () => {
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [products, setProducts] = useState([]);

  // Fetch categories and products on component mount
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const { data } = await axios.get(
          "https://partspluseg.com/public/api/Category"
        );
        setCategories(data);
        if (data.length > 0) {
          setSelectedCategory(data[0].id); // Set default category
          setProducts(data[0].product); // Set products for the default category
        }
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  // Handle category change
  const handleCategoryChange = (categoryId) => {
    setSelectedCategory(categoryId);
    const category = categories.find((cat) => cat.id === categoryId);
    setProducts(category?.product || []);
  };

  return (
    <>
      <section className="product-area pt-120 pb-120">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="section-title text-center">
                <h2 className="section-main-title mb-35">
                   ALL Products 
                </h2>
              </div>
            </div>
          </div>
          <div className="product-tab-wrapper">
            <div className="product-tab-nav mb-60">
              <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                  {categories.map((category) => (
                    <button
                      key={category.id}
                      className={`nav-link ${
                        selectedCategory === category.id ? "active" : ""
                      }`}
                      onClick={() => handleCategoryChange(category.id)}
                      type="button"
                      role="tab"
                    >
                      {category.name}
                      <span className="total-product">
                        [{category.product_count}]
                      </span>
                    </button>
                  ))}
                </div>
              </nav>
            </div>
            <div className="product-tab-content">
              <div className="tab-content" id="nav-tabContent">
                <div
                  className="tab-pane fade show active"
                  role="tabpanel"
                >
                  <div className="products-wrapper">
                    {products.length > 0 ? (
                      products.map((item) => (
                        <SingleProductCard key={item.id} item={item} />
                      ))
                    ) : (
                      <p>No products available for this category.</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="product-area-btn mt-10 text-center">
                <Link href="/shop-sidebar-5-column" className="border-btn">
                  View All Products
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProductArea;
