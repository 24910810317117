"use client";

import React, { useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import Image from "next/image";
import Link from "next/link";
import { cart_product } from "@/redux/slices/cartSlice";
import { cart_compare_product } from "@/redux/slices/compareSlice";
import { wishlist_product } from "@/redux/slices/wishlistSlice";
import { toast } from "react-toastify";

// Define TypeScript Interfaces
interface ProductVariant {
  id: string;
  size: string;
  stock: number;
}

interface Category {
  name: string;
}

interface ProductImage {
  image: string;
}

export interface ProductsType {
  id: string;
  name: string;
  price: string;
  PriceAfterDiscount?: string;
  product_image?: ProductImage[];
  product_variants?: ProductVariant[];
  category?: Category;
  buy_xget_y?: {
    X: string;
    Y: string;
    expiration: string;
  }[];
}

interface PropsType {
  item: ProductsType;
}

const SingleProductCard: React.FC<PropsType> = React.memo(({ item }) => {
  const dispatch = useDispatch();
  const [selectedVariant, setSelectedVariant] = useState<string | "">("");
  const [selectedStock, setSelectedStock] = useState<number>(0);
  const [isAddingToCart, setIsAddingToCart] = useState<boolean>(false);

  // Ensure product_images is an array before mapping
  const productImages =
    Array.isArray(item?.product_image) && item.product_image.length
      ? item.product_image.map((img) => `https://partspluseg.com/${img.image}`)
      : ["https://partspluseg.com/R4NqAqlkGGXeTzjH5dZ3HBHDCKnHcxpm3jJ8LjNR.webp"]; // Fallback image

  // Memoize discount percentage calculation
  const discountPercentage = useMemo(() => {
    const price = Number(item?.price) || 0;
    const priceAfterDiscount = Number(item?.PriceAfterDiscount) || price;
    if (price > 0 && priceAfterDiscount < price) {
      return Math.round(((price - priceAfterDiscount) / price) * 100);
    }
    return 0;
  }, [item?.price, item?.PriceAfterDiscount]);

  // Handle Add to Cart logic with toast notifications
  const handleAddToCart = () => {
    if (selectedVariant && selectedStock > 0) {
      setIsAddingToCart(true);
      const newCartProduct = {
        ...item,
        size: selectedVariant,
        stock: selectedStock,
      };
      dispatch(cart_product(newCartProduct));
      toast.success(`${item.name} (Size: ${selectedVariant}) added to cart!`);
      setIsAddingToCart(false);
    } else {
      toast.error("Please select a valid size with available stock.");
    }
  };

  // Safeguard price values
  const price = Number(item?.price) || 0;
  const priceAfterDiscount = Number(item?.PriceAfterDiscount) || price;

  // Check if all variants are out of stock
  const allOutOfStock = Array.isArray(item?.product_variants)
    ? item.product_variants.every((variant) => variant.stock === 0)
    : true;

  // Handle the selection of size
  const handleSizeSelect = (variantSize: string, variantStock: number) => {
    setSelectedVariant(variantSize);
    setSelectedStock(variantStock);
  };

  // Early return if item is not provided
  if (!item) {
    return <p>Loading...</p>;
  }

  return (
    <div className="single-product">
      <div className="product-image pos-rel">
        <Link href={`/shop-details/${item.id}`}>
          <Swiper
            modules={[Navigation, Pagination]}
            spaceBetween={10}
            slidesPerView={1}
            loop={true}
            navigation={true}
            pagination={{ clickable: true }}
            className="product-swiper"
          >
            {productImages.map((image, index) => (
              <SwiperSlide key={index}>
                <Image
                  src={image}
                  alt={item.name}
                  width={500}
                  height={500}
                  sizes="(max-width: 768px) 100vw, 500px"
                  loading="lazy"
                  style={{ width: "100%", height: "auto" }}
                  onError={(e) => {
                    e.currentTarget.src = "/default-image.png"; // Fallback image
                  }}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Link>

        <div className="product-action">
          <button
            onClick={() => dispatch(wishlist_product(item))}
            type="button"
            className="wishlist-btn"
            aria-label="Add to Wishlist"
          >
            <i className="fal fa-heart"></i>
          </button>
          <button
            onClick={() => dispatch(cart_compare_product(item))}
            type="button"
            className="compare-btn"
            aria-label="Compare Product"
          >
            <i className="fal fa-exchange"></i>
          </button>
        </div>

        <div className="product-action-bottom">
          <button
            onClick={handleAddToCart}
            type="button"
            className="add-cart-btn"
            disabled={!selectedVariant || selectedStock === 0 || isAddingToCart}
            title={
              !selectedVariant
                ? "Please select a size"
                : selectedStock === 0
                ? "Selected size is out of stock"
                : ""
            }
          >
            <i className="fal fa-shopping-bag"></i>
            {isAddingToCart ? "Adding..." : "Add to Cart"}
          </button>
        </div>

        {/* Discount Label */}
        {discountPercentage > 0 && (
          <div className="product-sticker-wrapper">
            <span className="product-sticker sale">{`${discountPercentage}% OFF`}</span>
          </div>
        )}

        {/* Buy X Get Y Offer */}
        {item.buy_xget_y?.length > 0 && (
          <div className="product-offer">
            <p>
               Buy {item.buy_xget_y[0].X} Get {item.buy_xget_y[0].Y} Free
            </p>
          </div>
        )}
      </div>

      <div className="product-desc">
        {/* Out of Stock Message Above Product Title */}
        {allOutOfStock && (
          <p role="alert" className="out-of-stock-message">
            This product is out of stock
          </p>
        )}

        <div className="product-name">
          <Link href={`/shop-details/${item.id}`}>{item?.name}</Link>
        </div>

        {/* Price Display */}
        <div className="product-price">
          {discountPercentage > 0 ? (
            <>
              <span className="price-now">{priceAfterDiscount.toFixed(2)} EGP</span>
              <span className="price-old">{price.toFixed(2)} EGP</span>
            </>
          ) : (
            <span className="price-now">{price.toFixed(2)} EGP</span>
          )}
        </div>

        {/* Size Selection as Buttons */}
        {Array.isArray(item?.product_variants) && item.product_variants.length > 0 && (
          <div className="product-size">
            {allOutOfStock ? (
              <p role="alert" className="out-of-stock-text">
                Product is out of stock
              </p>
            ) : (
              <div className="size-buttons">
                <p>Select Size:</p>
                {item.product_variants.map((variant) => (
                  <button
                    key={variant.id}
                    type="button"
                    className={`size-button ${selectedVariant === variant.size ? "selected" : ""}`}
                    disabled={variant.stock === 0}
                    onClick={() => handleSizeSelect(variant.size, variant.stock)}
                    style={{
                      cursor: variant.stock === 0 ? "not-allowed" : "pointer",
                    }}
                  >
                    {variant.size} {variant.stock === 0 && "(Out of Stock)"}
                  </button>
                ))}
              </div>
            )}
            {/* Display message if selected size is out of stock */}
            {selectedVariant && selectedStock === 0 && (
              <p role="alert" className="out-of-stock-message">
                This size is out of stock
              </p>
            )}
          </div>
        )}

        {/* Category Information */}
        <div className="product-category">
          <span>Category: {item?.category?.name ?? "Uncategorized"}</span>
        </div>
      </div>
    </div>
  );
});

export default SingleProductCard;
